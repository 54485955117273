import React from "react";
import Dashboard from "../components/Dashboard";

function ViewDashboard() {
  return (
    <div>
      <Dashboard />
    </div>
  );
}

export default ViewDashboard;
