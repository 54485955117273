import AppLayout from '../components/AppLayout'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle, TextField
} from '@mui/material'
import Table from '@mui/material/Table'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import TableBody from '@mui/material/TableBody'
import TableContainer from '@mui/material/TableContainer'
import {useEffect, useState} from 'react'

function ViewBillingPeriods() {
  const [billingPeriods, setBillingPeriods] = useState([])
  const [showSettleConfirm, setShowSettleConfirm] = useState(false)
  const [periodToSettle, setPeriodToSettle] = useState({})
  const [newPeriodFirstDay, setNewPeriodFirstDay] = useState('')
  const [newPeriodLastDay, setNewPeriodLastDay] = useState('')
  const [showCreateBillingPeriod, setShowCreateBillingPeriod] = useState(false)

  const loadBillingPeriods = async () => {
    const response = await fetch('/api/v1/billing-periods')
    const result = await response.json()
    setBillingPeriods(result.billing_periods)
    const latest = result.billing_periods.reduce(
      (latest, {last_day}) => (latest < new Date(last_day)) ? new Date(last_day) : latest,
      new Date('2021-01-01'))
    latest.setDate(latest.getDate() + 1)
    setNewPeriodFirstDay(latest.toISOString().substring(0, 10))
  }

  const createBillingPeriod = async () => {
    await fetch('/api/v1/billing-periods', {
      method: 'POST',
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ first_day: newPeriodFirstDay, last_day: newPeriodLastDay })
    })
    handleClose()
  }

  const newPeriodEndDateSelected = (date) => {
    const nextDay = new Date(date)
    nextDay.setDate(nextDay.getDate() + 1)
    if (date > newPeriodFirstDay && nextDay.getDate() === 1) {
      setNewPeriodLastDay(date)
    } else {
      setNewPeriodLastDay(null)
    }
  }

  useEffect(() => {
    loadBillingPeriods()
  }, [showSettleConfirm, showCreateBillingPeriod])

  const handleClose = () => {
    setPeriodToSettle({})
    setShowSettleConfirm(false)
    setShowCreateBillingPeriod(false)
  }

  const settle = async () => {
    await fetch('/api/v1/billing-periods/' + periodToSettle.id + '/settle', { method: 'POST' })
    handleClose()
  }

  const confirmSettle = (period) => {
    setPeriodToSettle(period)
    setShowSettleConfirm(true)
  }

  const period_actions = (period) => {
    if (period.settled) {
      return <Button onClick={e => document.location = '/api/v1/billing-periods/' + period.id + '/report?format=excel'}>Report</Button>
    } else if (new Date(period.last_day) < new Date()) {
      return <Button onClick={e => confirmSettle(period)}>Settle</Button>
    } else if (new Date(period.first_day) > new Date()) {
      return <Button disabled>Future period</Button>
    } else {
      return <Button disabled>Current period</Button>
    }
  }

  return (
    <AppLayout>
      <Dialog open={showSettleConfirm}
              onClose={handleClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">Run settlement for period?</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Should the settlement process for the period from {periodToSettle.first_day} to {periodToSettle.last_day} be run?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} autoFocus>Cancel</Button>
          <Button onClick={settle}>Settle</Button>
        </DialogActions>
      </Dialog>

      <Dialog open={showCreateBillingPeriod}
              onClose={handleClose}>
        <DialogTitle>Create next billing period</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Select ending date for the new period, which is going to start on {newPeriodFirstDay}.  The date must
            be the last day of the month.
          </DialogContentText>
          <Box>
            <TextField id="date"
                       variant="outlined"
                       required
                       pattern="\d{4}-\d{2}-\d{2}"
                       type="date"
                       inputProps={{
                         min: newPeriodFirstDay,
                       }}
                       onChange={(e) => newPeriodEndDateSelected(e.target.value)}/>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} autoFocus>Cancel</Button>
          <Button onClick={createBillingPeriod} disabled={!newPeriodLastDay}>Create Billing Period</Button>
        </DialogActions>
      </Dialog>

      <Box sx={{display: "flex", justifyContent: "flex-begin"}}>
        <h2>Billing Periods</h2>
      </Box>
      <Box className="table-container-print">
        <TableContainer>
          <Table className="table-print" size="small">
            <TableHead sx={{ backgroundColor: "#eee" }}>
              <TableRow>
                <TableCell sx={{fontWeight: "bold"}}>Start</TableCell>
                <TableCell sx={{fontWeight: "bold"}}>End</TableCell>
                <TableCell sx={{fontWeight: "bold"}}>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {
                billingPeriods.map((period) => {
                  const {id, first_day, last_day} = period
                  return (
                    <TableRow key={id}>
                      <TableCell>{first_day}</TableCell>
                      <TableCell>{last_day}</TableCell>
                      <TableCell>{period_actions(period)}</TableCell>
                    </TableRow>
                  )
                })
              }
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <Button onClick={() => setShowCreateBillingPeriod(true)}>Create next billing period</Button>
    </AppLayout>
  );
}

export default ViewBillingPeriods;
