import TableContainer from '@mui/material/TableContainer'
import Table from '@mui/material/Table'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import TableBody from '@mui/material/TableBody'
import {useEffect, useState} from 'react'
import {Box, CircularProgress, TableFooter, TablePagination, Typography} from '@mui/material'

const LoggedHours = (props) => {
    const [workEntries, setWorkEntries] = useState();
    const [openSpinner, setOpenSpinner] = useState(true);
    const [count, setCount] = useState();
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const workEntriesUrl = props.household ? `/api/v1/households/${props.household.id}/work-entries` : "/api/v1/households/work-entries"

    useEffect(() => {
        const getWorkEntries = async () => {
            try {
                const response = await fetch(workEntriesUrl);
                const result = await response.json();
                setWorkEntries(result.work_entries.reverse());
                setCount(result.work_entries.length);
                setOpenSpinner(false);
                console.log("Work entries: ", result);
            } catch (error) {
                console.log("Unable to retrieve work entries.", error);
            }
        };

        getWorkEntries();
    }, [workEntriesUrl]);

    return <>
        {openSpinner && (
            <Box sx={{display: "flex", justifyContent: "center", mt: "2rem"}}>
                <CircularProgress color="primary"/>
            </Box>
        )}
        <TableContainer>
            <Table sx={{width: "90%", mr: "0.5rem"}} size="small">
                <TableHead sx={{backgroundColor: "#eee"}}>
                    <TableRow>
                        <TableCell>Task</TableCell>
                        <TableCell>Work Group</TableCell>
                        <TableCell>Posted by</TableCell>
                        <TableCell>Date</TableCell>
                        <TableCell>Time</TableCell>
                        { props.makeLogEntryActions ? <TableCell>Action</TableCell> : <></> }
                    </TableRow>
                </TableHead>
                <TableBody>
                    {workEntries?.length ? (
                        workEntries
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((logEntry, i) => {
                                return (
                                    <TableRow hover={true} key={i}>
                                        <TableCell>{logEntry.description}</TableCell>
                                        <TableCell>{logEntry.workgroup}</TableCell>
                                        <TableCell>{logEntry.user_name}</TableCell>
                                        <TableCell>
                                            {new Date(logEntry.date).toLocaleDateString(
                                                undefined,
                                                {
                                                    month: "2-digit",
                                                    day: "2-digit",
                                                    year: "numeric"
                                                }
                                            )}
                                        </TableCell>
                                        <TableCell>{logEntry.time_served}</TableCell>
                                        {
                                            props.makeLogEntryActions ?
                                                <TableCell>{props?.makeLogEntryActions(logEntry)}</TableCell>
                                                : <></>
                                        }
                                    </TableRow>
                                );
                            })
                    ) : (
                        <TableRow>
                            <TableCell colSpan={6}>
                                <Box sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    padding: "2rem"
                                }}>
                                    <Typography variant="subtitle1" color="text.secondary">
                                        No entries found ...
                                    </Typography>
                                </Box>
                            </TableCell>
                        </TableRow>
                    )}
                </TableBody>{" "}
                {workEntries && workEntries.length > 10 && (
                    <TableFooter>
                        <TableRow>
                            <TableCell colSpan={6} sx={{border: "none"}}>
                                <TablePagination component="div"
                                                 count={count}
                                                 page={page}
                                                 onPageChange={(event, newPage) => {
                                                     setPage(newPage);
                                                 }}
                                                 onRowsPerPageChange={(e) => {
                                                     setRowsPerPage(parseInt(e.target.value, 10));
                                                     setPage(0);
                                                 }}
                                                 rowsPerPage={rowsPerPage}
                                                 rowsPerPageOptions={[5, 10, 25]}/>
                            </TableCell>
                        </TableRow>
                    </TableFooter>
                )}
            </Table>
        </TableContainer>
    </>
}

export default LoggedHours
