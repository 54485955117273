import React from "react";
import { Typography, Box, Button } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import "../App.css";
import { useContext, useState, useEffect } from "react";
import { AuthContext } from "../contexts/AuthContext";
import { HoursContext } from "../contexts/HoursContext";
import { useNavigate } from "react-router-dom";

function CreateReport(props) {
  const { user } = useContext(AuthContext);
  const { currentPeriod, hours } = useContext(HoursContext);

  const [workEntries, setWorkEntries] = useState();
  const [workgroups, setWorkgroups] = useState();
  const navigate = useNavigate()

  function triggerPrint() {
    setTimeout(() => window.print(), 600);
  }

  useEffect(() => {
    const getWorkgroups = async () => {
      const response = await fetch("/api/v1/workgroups");
      const result = await response.json();
      setWorkgroups(result.workgroups);
    };
    const getWorkEntries = async () => {
      try {
        const response = await fetch("/api/v1/households/work-entries");
        const result = await response.json();
        setWorkEntries(result.work_entries.reverse());
        triggerPrint();
        console.log("Work entries: ", result);
      } catch (error) {
        console.log("Unable to retrieve work entries.", error);
      }
    };
    getWorkgroups();
    getWorkEntries();
  }, []);

  return (
    <Box id="content" style={{ width: "100%" }}>
      <Box sx={{display: "flex", justifyContent: "space-between", alignContent: "flex-start"}}>      <img
        src="https://berlin-bilingual-school.de/themes/custom/b5/images/BBS_final_logo.png"
        alt="Logo of Berlin Bilingual School"
        height="75"
        style={{ margin: "1rem", cursor: "pointer" }}
        onClick={() => navigate("/")}
      /><Box sx={{margin: "1rem"}}><Button variant="outlined" onClick={() => navigate("/")}>Return</Button></Box>
      </Box>

      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <Typography>
          Family: {user && user.household_name} - Period:{" "}
          {currentPeriod && currentPeriod}
        </Typography>
      </Box>
      <Box className="table-container-print">
        <Table className="table-print" size="small">
          <TableHead sx={{ backgroundColor: "#eee" }}>
            <TableRow>
              <TableCell sx={{fontSize: "13px", fontWeight: "bold"}}>Task</TableCell>
              <TableCell sx={{fontSize: "13px", fontWeight: "bold"}}>Workgroup</TableCell>
              <TableCell sx={{fontSize: "13px", fontWeight: "bold"}}>Posted by</TableCell>
              <TableCell sx={{fontSize: "13px", fontWeight: "bold"}}>Date</TableCell>
              <TableCell sx={{fontSize: "13px", fontWeight: "bold"}}>Time</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {workgroups && workEntries && workEntries.length > 0 ? (
              workEntries.map((element, i) => {
                return (
                  <TableRow key={i}>
                    <TableCell sx={{ verticalAlign: "top", fontSize: "12px" }}>
                      {element.description}
                    </TableCell>
                    <TableCell sx={{ verticalAlign: "top", fontSize: "12px" }}>
                      {element.workgroup}
                    </TableCell>
                    <TableCell sx={{ width: "100px", verticalAlign: "top", fontSize: "12px" }}>
                      {element.user_name}
                    </TableCell>
                    <TableCell sx={{ width: "75px", verticalAlign: "top", fontSize: "12px" }}>
                      {element.date}
                    </TableCell>
                    <TableCell sx={{ verticalAlign: "top", fontSize: "12px" }}>
                      {element.time_served}
                    </TableCell>
                  </TableRow>
                );
              })
            ) : (
              <TableRow>
                <TableCell colSpan={6}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      padding: "2rem",
                    }}
                  >
                    <Typography variant="subtitle1" color="text.secondary">
                      No entries found ...
                    </Typography>
                  </Box>
                </TableCell>
              </TableRow>
            )}

            <TableRow sx={{ backgroundColor: "#eee" }}>
              <TableCell colSpan={4}>
                <Typography variant="body2" sx={{ fontWeight: "bold", fontSize: "12px" }}>
                  Target (2 Parent/Guardian)
                </Typography>
              </TableCell>
              <TableCell variant="body2" sx={{ fontWeight: "bold", fontSize: "12px" }}>
                {hours && hours.time_required}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell colSpan={4}>
                <Typography variant="body2" sx={{ fontWeight: "bold", fontSize: "12px" }}>
                  Total Time Logged
                </Typography>
              </TableCell>
              <TableCell variant="body2" sx={{ fontWeight: "bold", fontSize: "12px" }}>
                {hours && hours.time_served}
              </TableCell>
            </TableRow>
            <TableRow sx={{ backgroundColor: "#eee" }}>
              <TableCell colSpan={4}>
                <Typography variant="body2" sx={{ fontWeight: "bold", fontSize: "12px" }}>
                  Credit from Previous Period
                </Typography>
              </TableCell>
              <TableCell variant="body2" sx={{ fontWeight: "bold", fontSize: "12px" }}>
                {hours && hours.previous_carry_over}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell colSpan={5}>
                <Typography variant="body2" sx={{ fontWeight: "bold", fontSize: "12px" }}>
                  {hours && hours.remaining} Hours remaining
                </Typography>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          mb: "3rem",
          fontSize: "12px"
        }}
      >
        Document generated on:{" "}
        {new Date().toLocaleString("en-En", {
          year: "numeric",
          month: "long",
          day: "numeric",
          hour: "numeric",
          minute: "numeric",
        })}
      </Box>
    </Box>
  );
}

export default CreateReport;
