import React from "react";
import CreateReport from "../components/CreateReport";

function ViewCreateReport() {
  return (
    <div>
      <CreateReport />
    </div>
  );
}

export default ViewCreateReport;
