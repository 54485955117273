import React from "react";
import {
    Box,
    Button
} from "@mui/material";

import {Link, useNavigate} from "react-router-dom";
import "../App.css";
import {useContext, useState, useEffect} from "react";
import {WorkEntriesContext} from "../contexts/WorkEntriesContext";
import ParentPage from './ParentPage'
import LoggedHours from './LoggedHours'

function Dashboard() {
    const {setTempEntry} = useContext(WorkEntriesContext);
    const navigate = useNavigate();
    const [workgroups, setWorkgroups] = useState();

    useEffect(() => {
        const getWorkgroups = async () => {
            const response = await fetch("/api/v1/workgroups");
            const result = await response.json();
            setWorkgroups(result.workgroups);
        };
        getWorkgroups();
    }, []);


    const getWorkgroupId = (menuOptions, workgroup) => {
        const id_element = menuOptions.filter((e) => e.name === workgroup);
        return id_element[0].id;
    };

    const makeEditButton = (logEntry) =>
        <Button variant="standard"
                onClick={() => {
                    setTempEntry({
                        id: logEntry.id,
                        description: logEntry.description,
                        user_id: logEntry.user_id,
                        date: logEntry.date,
                        time_served: logEntry.time_served,
                        workgroup: logEntry.workgroup,
                        workgroup_id: getWorkgroupId(
                            workgroups,
                            logEntry.workgroup
                        ),
                        edit: true
                    });
                    navigate("/loghours");
                }}>
            Edit
        </Button>

    useEffect(() => {
        setTempEntry({edit: false});
    }, [setTempEntry]);

    return (
        <ParentPage>
            <Box sx={{display: "flex", justifyContent: "flex-end"}}>
                <Box>
                    <Link to="/loghours">
                        <Button variant="contained"
                                sx={{
                                    backgroundColor: "#BF3228",
                                    mt: "2rem",
                                    mb: "2rem",
                                    mr: "0.5rem"
                                }}>
                            + Add new task
                        </Button>
                    </Link>
                </Box>
                <Box>
                    <Button variant="contained"
                            sx={{
                                backgroundColor: "#BF3228",
                                mt: "2rem",
                                mb: "2rem",
                                mr: "0.5rem"
                            }}
                            onClick={() => navigate("/create-report")}>
                        + Export
                    </Button>
                </Box>
            </Box>
            <LoggedHours makeLogEntryActions={makeEditButton}/>
        </ParentPage>
    );
}

export default Dashboard;
