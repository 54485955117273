import React from "react";
import { Box, Typography, LinearProgress, Tooltip } from "@mui/material";
import { useContext } from "react";
import { HoursContext } from "../contexts/HoursContext";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

function WorkHoursStatusWidget() {
  const { hours, billingPeriods, setPeriodId, setCurrentPeriod, setFirstDay } =
    useContext(HoursContext);

  if (!hours) {
    return <></>
  }

  const getOldestOpenPeriod = (billingPeriods) => {
    const openPeriods = [
      ...billingPeriods.filter((element) => {
        return element.settled === false;
      }),
    ];
    const oldestDate = new Date(
      Math.min(
        ...openPeriods.map((element) => {
          return new Date(element.first_day);
        })
      )
    );

    const oldestPeriod = [
      ...openPeriods.filter((element) => {
        return (
          new Date(element.first_day).toLocaleDateString("en-EN", {
            month: "2-digit",
            year: "2-digit",
          }) ===
          oldestDate.toLocaleDateString("en-EN", {
            month: "2-digit",
            year: "2-digit",
          })
        );
      }),
    ];

    setPeriodId(oldestPeriod[0].id);
    setFirstDay(oldestPeriod[0].first_day);

    const oldestPeriodToString = `${new Date(
      oldestPeriod[0].first_day
    ).toLocaleDateString("en-EN", {
      month: "short",
      year: "2-digit",
    })} - ${new Date(oldestPeriod[0].last_day).toLocaleDateString("en-EN", {
      month: "short",
      year: "2-digit",
    })}`;
    setCurrentPeriod(oldestPeriodToString);
    return oldestPeriodToString;
  };

  return (
      <Box sx={{
        display: "flex",
        alignItems: "flex-end"
      }}>

        <Box sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-end",
          alignItems: "flex-start",
          width: "100%"
        }}>
          <Box className="widget-box-container">
            <Box className="widget-box-itemContainer">
              <Box className="widget-box-center">
                <Box sx={{display: "flex", alignItems: "center"}}>
                  <Typography variant="subtitle1" color="text.secondary" mr={0.5}>
                    Billing Period{" "}
                  </Typography>{" "}
                  <Tooltip title="The current billing period."
                           arrow
                           placement="top">
                    <InfoOutlinedIcon fontSize="small" sx={{color: "gray"}}/>
                  </Tooltip>
                </Box>

                <Typography variant="h6">
                  {billingPeriods?.length && getOldestOpenPeriod(billingPeriods)}
                </Typography>
              </Box>
              <Box className="widget-box-item">
                <Box sx={{display: "flex", alignItems: "center"}}>
                  <Typography variant="subtitle1" color="text.secondary" mr={0.5}>
                    Target
                  </Typography>{" "}
                  <Tooltip title="The hours required for this billing period."
                           arrow
                           placement="top">
                    <InfoOutlinedIcon fontSize="small" sx={{color: "gray"}}/>
                  </Tooltip>
                </Box>

                <Typography sx={{fontSize: "20px"}}>
                  {hours && hours.time_required
                      ? hours.time_required
                      : "none required"}
                </Typography>
              </Box>
              <Box className="widget-box-item">
                <Box sx={{display: "flex", alignItems: "center"}}>
                  <Typography variant="subtitle1" color="text.secondary" mr={0.5}>
                    Logged
                  </Typography>{" "}
                  <Tooltip title="Hours logged for activities in this billing period."
                           arrow
                           placement="top">
                    <InfoOutlinedIcon fontSize="small" sx={{color: "gray"}}/>
                  </Tooltip>
                </Box>

                <Typography sx={{fontSize: "20px"}}>
                  {hours && hours.time_served ? hours.time_served : "none"}
                </Typography>
              </Box>
              <Box className="widget-box-item">
                <Box sx={{display: "flex", alignItems: "center"}}>
                  <Typography variant="subtitle1" color="text.secondary" mr={0.5}>
                    Previous
                  </Typography>{" "}
                  <Tooltip title="Hour credits from previous billing period."
                           arrow
                           placement="top">
                    <InfoOutlinedIcon fontSize="small" sx={{color: "gray"}}/>
                  </Tooltip>
                </Box>
                <Box>
                  <Typography sx={{fontSize: "20px"}}>
                    {hours && hours.previous_carry_over
                        ? hours.previous_carry_over
                        : "none"}
                  </Typography>
                </Box>
              </Box>{" "}
              <Box className="widget-box-item">
                <Box sx={{display: "flex", alignItems: "center"}}>
                  <Typography variant="subtitle1" color="text.secondary" mr={0.5}>
                    Remaining
                  </Typography>{" "}
                  <Tooltip title="Outstanding hours in this billing period."
                           arrow
                           placement="top">
                    <InfoOutlinedIcon fontSize="small" sx={{color: "gray"}}/>
                  </Tooltip>
                </Box>
                <Box>
                  <Typography sx={{fontSize: "20px"}}>
                    {hours && hours.remaining ? hours.remaining : "none"}
                  </Typography>
                </Box>
              </Box>
            </Box>

            <Box mt={1}>
              <Box sx={{position: "relative"}}>
                <Box sx={{
                  position: "absolute",
                  left: "50%",
                  zIndex: "1"
                }}>
                  <Typography variant="body1"
                              color={hours && hours.percent_done < 50 ? "black" : "#fff"}>
                    {hours && hours.percent_done ? hours.percent_done : 0}%
                  </Typography>
                </Box>

                <LinearProgress variant="determinate"
                                value={hours && hours.percent_done ? hours.percent_done : 0}
                                sx={{
                                  height: "23px",
                                  borderRadius: "25px",
                                  backgroundColor: "#eee"
                                }}
                />
                <Box className="days-remaining">
                  <Typography>
                    {hours && hours.days_remaining} days remaining{" "}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
  );
}

export default WorkHoursStatusWidget;
