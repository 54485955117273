import { Typography, Box, Button } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";

function NotFound() {
  const navigate = useNavigate();
  return (
    <div>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          height: "80vh",
        }}
      >
        <Box sx={{ m: "2rem", maxWidth: "32rem" }}>
          {" "}
          <Typography variant="h4" color="primary">
            404
          </Typography>
          <Typography variant="h2" component="h1">
            Not found
          </Typography>
          <Typography color="text.secondary">
            The page you are looking for does not exist. Click the button below
            to return to the homepage.
          </Typography>
          <Button
            variant="contained"
            onClick={() => navigate("/")}
            sx={{ mt: "2rem" }}
          >
            Home
          </Button>
        </Box>
      </Box>
    </div>
  );
}

export default NotFound;
