import {Box, Grid} from '@mui/material'
import Navbar from './Navbar'
import {useContext} from 'react'
import {AuthContext} from '../contexts/AuthContext'

function AppLayout(props) {
  const { user } = useContext(AuthContext);

  return (
  <div>
    {" "}
    <Grid container spacing={2}>
      <Grid item xs={12} sm={12} md={2}>
        <Box sx={{ background: "linear-gradient(#eee, #fff)", height: "100%", minWidth: "175px"}}>
          <Navbar user={user} />
        </Box>
      </Grid>
      <Grid item xs={12} sm={12} md={10}>
        {props.children}
      </Grid>
    </Grid>
  </div>
  )
}

export default AppLayout;
