import {Box} from '@mui/material'
import ParentWelcome from './ParentWelcome'
import {useContext} from 'react'
import {AuthContext} from '../contexts/AuthContext'
import WorkHoursStatusWidget from './WorkHoursStatusWidget'
import AppLayout from './AppLayout'

function ParentPage(props) {
  const { user } = useContext(AuthContext);

  return (
      <AppLayout>
          <Box className="welcome-widget-container">
              <ParentWelcome user={user}/>
              <WorkHoursStatusWidget/>
          </Box>
          {props.children}
      </AppLayout>
  )
}

export default ParentPage;
