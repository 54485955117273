import { createContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

export const AuthContext = createContext();

export const AuthContextProvider = (props) => {
  const [session, setSession] = useState();
  const [workgroups, setWorkgroups] = useState();
  const [user, setUser] = useState();
  const navigate = useNavigate();

  useEffect(() => {
    const isLoggedIn = async () => {
      try {
        const response = await fetch("/api/v1/me");
        if (response.status === 403) {
          console.log("Not logged in");
          localStorage.setItem('path', document.location.pathname);
          document.location.href = "/login";
        } else if (response.status === 200) {
          const result = await response.json();
          setUser(result);
          console.log("User is logged in.", result);
          const path = localStorage.getItem('path')
          if (path) {
            localStorage.removeItem('path');
            navigate(path);
          }
        } else {
          console.log("User not logged in.", response.statusText);
        }
      } catch (error) {
        console.log("Unable to log in.", error);
      }
    };
    isLoggedIn();
    //eslint-disable-next-line
  }, []);

  return (
    <>
      <AuthContext.Provider
        value={{
          session,
          setSession,
          workgroups,
          setWorkgroups,
          user,
          setUser,
        }}
      >
        {props.children}
      </AuthContext.Provider>
    </>
  );
};
