import { createContext, useState, useEffect } from "react";

export const HoursContext = createContext();

export const HoursContextProvider = (props) => {
  const [hours, setHours] = useState();
  const [billingPeriods, setBillingPeriods] = useState([]);
  const [currentPeriod, setCurrentPeriod] = useState("");
  const [periodId, setPeriodId] = useState();
  const [isUpdated, setIsUpdated] = useState();
  const [firstDay, setFirstDay] = useState();
  const balanceUrl = props.household ? `/api/v1/households/${props.household.id}/balance` : "/api/v1/households/balance";

  const getHours = async () => {
    try {
      const response = await fetch(balanceUrl);
      const result = await response.json();
      setHours(result);
    } catch (error) {
      console.log("Unable to retrieve hours.", error);
    }
  };

  const getBillingPeriods = async () => {
    try {
      const response = await fetch("/api/v1/billing-periods/");
      const result = await response.json();
      setBillingPeriods(result.billing_periods);
    } catch (error) {
      console.log("Unable to retrieve billing period.", error);
    }
  };

  useEffect(() => {
    getBillingPeriods();
    getHours();
    // eslint-disable-next-line
  }, [balanceUrl]);

  return (
      <HoursContext.Provider value={{
          hours,
          billingPeriods,
          periodId,
          setIsUpdated,
          isUpdated,
          setPeriodId,
          getHours,
          currentPeriod,
          setCurrentPeriod,
          firstDay,
          setFirstDay,
      }}>
        {props.children}
      </HoursContext.Provider>
  );
};
