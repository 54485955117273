import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import ViewDashboard from "./views/ViewDashboard";
import ViewLogHours from "./views/ViewLogHours";
import ViewCreateReport from "./views/ViewCreateReport";
import { AuthContextProvider } from "./contexts/AuthContext";
import { HoursContextProvider } from "./contexts/HoursContext";
import { WorkEntriesContextProvider } from "./contexts/WorkEntriesContext";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import NotFound from "./components/NotFound";
import WorkHoursStatusWidget from './components/WorkHoursStatusWidget'
import ViewBillingPeriods from './views/ViewBillingPeriods'
import ViewHouseholds from './views/ViewHouseholds'
import ViewHousehold from "./views/ViewHousehold";

function App() {
  const theme = createTheme({
    palette: {
      primary: {
        main: "#BF3228",
      },
    },
  });
  return (
    <div>
      <Router>
        <AuthContextProvider>
          <HoursContextProvider>
            <WorkEntriesContextProvider>
              <ThemeProvider theme={theme}>
                <Routes>
                  <Route path="/" element={<ViewDashboard/>}/>
                  <Route path="/loghours" element={<ViewLogHours />}/>
                  <Route path="/create-report" element={<ViewCreateReport />}/>
                  <Route path="/widget" element={<WorkHoursStatusWidget />}/>
                  <Route path="/billing-periods" element={<ViewBillingPeriods/>}/>
                  <Route path="/households" element={<ViewHouseholds/>}/>
                  <Route path="/household/:householdId" element={<ViewHousehold />}/>
                  <Route path="*" element={<NotFound />} />
                </Routes>
              </ThemeProvider>
            </WorkEntriesContextProvider>
          </HoursContextProvider>
        </AuthContextProvider>
      </Router>
    </div>
  );
}

export default App;
