import React from "react";
import { useSearchParams } from 'react-router-dom'
import {
  Typography,
  Grid,
  Box,
  MenuItem,
  Divider,
  Button,
  TextField,
  FormControl,
  Snackbar,
  Select,
  InputAdornment,
  CircularProgress,
  Modal,
} from "@mui/material";

import { useNavigate } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import "../App.css";
import { useEffect, useState } from "react";
import WorkHoursStatusWidget from "./WorkHoursStatusWidget";
import { useContext } from "react";
import { AuthContext } from "../contexts/AuthContext";
import { WorkEntriesContext } from "../contexts/WorkEntriesContext";
import Navbar from "./Navbar";
import ParentWelcome from "./ParentWelcome";
import { HoursContext } from "../contexts/HoursContext";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";

function LogHours() {
  const { user } = useContext(AuthContext);
  const { tempEntry } = useContext(WorkEntriesContext);
  const { getHours, firstDay } = useContext(HoursContext);
  const [menuOptions, setMenuOptions] = useState();
  const [description, setDescription] = useState("");
  const [workgroup_id, setWorkgroup_id] = useState();
  const [date, setDate] = useState("");
  const [hours, setHours] = useState();
  const [min, setMin] = useState();
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [action, setAction] = useState();
  const [taskError, setTaskError] = useState(false);
  const [workgroupError, setWorkgroupError] = useState(false);
  const [dateError, setDateError] = useState(false);
  const [hoursError, setHoursError] = useState(false);
  const [minError, setMinError] = useState(false);
  const [openSpinner, setOpenSpinner] = useState(true);
  const [openModal, setOpenModal] = useState(false);
  const [searchParams] = useSearchParams();
  const logForHouseholdId = searchParams.get("householdId");

  const navigate = useNavigate();


  const currentDate = `${new Date().toLocaleDateString("en-EN", {
    year: "numeric",
  })}-${new Date().toLocaleDateString("en-EN", {
    month: "2-digit",
  })}-${new Date().toLocaleDateString("en-EN", {
    day: "2-digit",
  })}`;

  const createWorkEntry = async () => {
    setTaskError(false);
    setWorkgroupError(false);
    setDateError(false);
    setHoursError(false);
    setMinError(false);

    if (description === "") {
      setTaskError(true);
      setMessage("Enter a task!");
      setAction(
        <>
          <Button size="small" onClick={() => setOpen(false)}>
            <CloseIcon sx={{ color: "#BF3228" }} />
          </Button>
        </>
      );
      setOpen(true);
      return;
    }

    if (isNaN(workgroup_id)) {
      setWorkgroupError(true);
      setMessage("Select a workgroup!");
      setAction(
        <>
          <Button size="small" onClick={() => setOpen(false)}>
            <CloseIcon sx={{ color: "#BF3228" }} />
          </Button>
        </>
      );
      setOpen(true);
      return;
    }
    if (date === "") {
      setDateError(true);
      setMessage("Enter a date!");
      setAction(
        <>
          <Button size="small" onClick={() => setOpen(false)}>
            <CloseIcon sx={{ color: "#BF3228" }} />
          </Button>
        </>
      );
      setOpen(true);
      return;
    }

    if (date > currentDate) {
      setDateError(true);
      setMessage("Future dates not valid.");
      setAction(
        <>
          <Button size="small" onClick={() => setOpen(false)}>
            <CloseIcon sx={{ color: "#BF3228" }} />
          </Button>
        </>
      );
      setOpen(true);
      return;
    }

    // * HOURS VALIDATION

    if (hours === "") {
      setHoursError(true);

      setMessage("Hours field cannot be empty.");
      setAction(
        <>
          <Button size="small" onClick={() => setOpen(false)}>
            <CloseIcon sx={{ color: "#BF3228" }} />
          </Button>
        </>
      );
      setOpen(true);
      return;
    }

    const reg = /^\d+$/;
    if (reg.test(hours) === false) {
      setHoursError(true);
      setMessage("Hours must be numbers.");
      setAction(
        <>
          <Button size="small" onClick={() => setOpen(false)}>
            <CloseIcon sx={{ color: "#BF3228" }} />
          </Button>
        </>
      );
      setOpen(true);
      return;
    }

    // * MIN VALIDATION

    if (min === "") {
      setMinError(true);
      setMessage("Minute field cannot be empty.");
      setAction(
        <>
          <Button size="small" onClick={() => setOpen(false)}>
            <CloseIcon sx={{ color: "#BF3228" }} />
          </Button>
        </>
      );
      setOpen(true);
      return;
    }

    if (min > 59) {
      setMinError(true);
      setMessage("Minutes cannot be larger than 59min.");
      setAction(
        <>
          <Button size="small" onClick={() => setOpen(false)}>
            <CloseIcon sx={{ color: "#BF3228" }} />
          </Button>
        </>
      );
      setOpen(true);
      return;
    }

    if (reg.test(min) === false) {
      setMinError(true);
      setMessage("Minutes must be numbers ('00').");
      setAction(
        <>
          <Button size="small" onClick={() => setOpen(false)}>
            <CloseIcon sx={{ color: "#BF3228" }} />
          </Button>
        </>
      );
      setOpen(true);
      return;
    }

    if (`${hours}:${min}` === "00:00" || `${hours}:${min}` === "0:00" || `${hours}:${min}` === "0:0") {
      setHoursError(true);
      setMinError(true);
      setMessage("Hours and minutes cannot be zero.");
      setAction(
        <>
          <Button size="small" onClick={() => setOpen(false)}>
            <CloseIcon sx={{ color: "#BF3228" }} />
          </Button>
        </>
      );
      setOpen(true);
      return;
    }

    // * post request
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const newEntry = {
      description: description,
      date: date,
      workgroup_id: workgroup_id,
      time_served: `${hours}:${min}`,
    };

    try {
      console.log('logging for household id ', logForHouseholdId);
      const response = await fetch(logForHouseholdId ? `/api/v1/households/${logForHouseholdId}/work-entry` : "/api/v1/work-entries", {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify(newEntry),
      });
      if (response.status === 200) {
        getHours();
        navigate(-1);
      } else if(response.status === 400){
        const result = await response.json()
        
         setMessage(`Error: ${result.message} (${result.code})`);
      setAction(
        <>
          <Button size="small" onClick={() => setOpen(false)}>
            <CloseIcon sx={{ color: "#BF3228" }} />
          </Button>
        </>
      );
      setOpen(true);
      }
    } catch (error) {
      console.log("Unable to create new work entry.", error);
    }
  };

  // * EDIT WORK ENTRY

  const editWorkEntry = async (e) => {
    e.preventDefault();
    console.log(min);

    // * form validation

    setTaskError(false);
    setWorkgroupError(false);
    setDateError(false);
    setHoursError(false);
    setMinError(false);

    if (description === "") {
      setTaskError(true);
      setMessage("Enter a task!");
      setAction(
        <>
          <Button size="small" onClick={() => setOpen(false)}>
            <CloseIcon sx={{ color: "#BF3228" }} />
          </Button>
        </>
      );
      setOpen(true);
      return;
    }

    if (isNaN(workgroup_id)) {
      setWorkgroupError(true);
      setMessage("Select a workgroup!");
      setAction(
        <>
          <Button size="small" onClick={() => setOpen(false)}>
            <CloseIcon sx={{ color: "#BF3228" }} />
          </Button>
        </>
      );
      setOpen(true);
      return;
    }
    if (date === "") {
      setDateError(true);
      setMessage("Enter a date!");
      setAction(
        <>
          <Button size="small" onClick={() => setOpen(false)}>
            <CloseIcon sx={{ color: "#BF3228" }} />
          </Button>
        </>
      );
      setOpen(true);
      return;
    }

    if (date > currentDate) {
      setDateError(true);
      setMessage("Future dates not valid.");
      setAction(
        <>
          <Button size="small" onClick={() => setOpen(false)}>
            <CloseIcon sx={{ color: "#BF3228" }} />
          </Button>
        </>
      );
      setOpen(true);
      return;
    }

    // * HOURS VALIDATION

    if (hours === "") {
      setHoursError(true);

      setMessage("Hours field cannot be empty.");
      setAction(
        <>
          <Button size="small" onClick={() => setOpen(false)}>
            <CloseIcon sx={{ color: "#BF3228" }} />
          </Button>
        </>
      );
      setOpen(true);
      return;
    }

    const reg = /^\d+$/;
    if (reg.test(hours) === false) {
      setHoursError(true);
      setMessage("Hours must be numbers.");
      setAction(
        <>
          <Button size="small" onClick={() => setOpen(false)}>
            <CloseIcon sx={{ color: "#BF3228" }} />
          </Button>
        </>
      );
      setOpen(true);
      return;
    }

    // * MIN VALIDATION

    if (min === "") {
      setMinError(true);
      setMessage("Minute field cannot be empty.");
      setAction(
        <>
          <Button size="small" onClick={() => setOpen(false)}>
            <CloseIcon sx={{ color: "#BF3228" }} />
          </Button>
        </>
      );
      setOpen(true);
      return;
    }

    if (min > 59) {
      setMinError(true);
      setMessage("Minutes cannot be larger than 59min.");
      setAction(
        <>
          <Button size="small" onClick={() => setOpen(false)}>
            <CloseIcon sx={{ color: "#BF3228" }} />
          </Button>
        </>
      );
      setOpen(true);
      return;
    }

    if (reg.test(min) === false) {
      setMinError(true);
      setMessage("Minutes must be numbers ('00').");
      setAction(
        <>
          <Button size="small" onClick={() => setOpen(false)}>
            <CloseIcon sx={{ color: "#BF3228" }} />
          </Button>
        </>
      );
      setOpen(true);
      return;
    }

    if (`${hours}:${min}` === "00:00") {
      setHoursError(true);
      setMinError(true);
      setMessage("Hours and minutes cannot be zero.");
      setAction(
        <>
          <Button size="small" onClick={() => setOpen(false)}>
            <CloseIcon sx={{ color: "#BF3228" }} />
          </Button>
        </>
      );
      setOpen(true);
      return;
    }

    // * put request

    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const editedEntry = {
      description: description,
      date: date,
      workgroup_id: workgroup_id,
      time_served: `${hours}:${min}`,
    };
    console.log("Edited Entry: ", editedEntry);

    try {
      const response = await fetch(`/api/v1/work-entries/${tempEntry.id}`, {
        method: "PUT",
        headers: myHeaders,
        body: JSON.stringify(editedEntry),
      });
      if (response.status === 200) {
        getHours();
        navigate(-1);
      } else if(response.status === 400) {
        const result = await response.json()
        setMessage(`Error: ${result.message} (${result.code})`);
      setAction(
        <>
          <Button size="small" onClick={() => setOpen(false)}>
            <CloseIcon sx={{ color: "#BF3228" }} />
          </Button>
        </>
      );
      setOpen(true);
      }
    } catch (error) {
      console.log("Error:", error);
    }
  };

  const getWorkgroups = async () => {
    const response = await fetch("/api/v1/workgroups");
    const result = await response.json();
    setMenuOptions(result.workgroups);
    setOpenSpinner(false);
  };

  const handleDelete = async () => {
    try {
      const response = await fetch(`/api/v1/work-entries/${tempEntry.id}`, {
        method: "DELETE",
      });
      if (response.status === 204) {
        console.log("Work entry deleted.");
        getHours();
        navigate(-1);
      }
    } catch (error) {
      console.log("Unable to delete work entry.", error);
    }
  };

  useEffect(() => {
    getWorkgroups();
  }, []);

  useEffect(() => {
    const setEditedEntryVariables = () => {
      if (tempEntry.edit) {
        setDescription(tempEntry.description);
        setDate(tempEntry.date);
        setWorkgroup_id(tempEntry.workgroup_id);
        setMin(
          tempEntry.time_served.slice(
            tempEntry.time_served.length - 2,
            tempEntry.time_served.length
          )
        );
        setHours(
          tempEntry.time_served.slice(
            tempEntry.time_served.length - 5,
            tempEntry.time_served.length - 3
          )
        );
      } else {
        setMin("00");
        setHours("00");
      }
    };
    setEditedEntryVariables();
  }, [tempEntry]);

  return (
    <>
      <Snackbar
        open={open}
        autoHideDuration={5000}
        onClose={() => setOpen(false)}
        message={message}
        action={action}
      />

      <Modal open={openModal} onClose={() => setOpenModal(false)}>
        <Box className="modalStyle">
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <WarningAmberIcon fontSize="large" color="primary" />
          </Box>

          <Typography m={2} variant="h6">
            Delete this entry?
          </Typography>
          <Box
            sx={{
              padding: "1rem",
              mb: "1rem",
              borderRadius: "0.5rem",
              backgroundColor: "#eee",
            }}
          >
            <Typography>
              {tempEntry && tempEntry.edit ? tempEntry.description : <></>}
            </Typography>
            <Typography>
              {tempEntry && tempEntry.edit ? tempEntry.workgroup : <></>}
            </Typography>
            <Typography>
              {tempEntry && tempEntry.edit ? (
                new Date(tempEntry.date).toLocaleDateString(undefined, {
                  year: "numeric",
                  month: "2-digit",
                  day: "2-digit",
                })
              ) : (
                <></>
              )}
            </Typography>
            <Typography>
              {tempEntry && tempEntry.edit ? (
                tempEntry.time_served + " h"
              ) : (
                <></>
              )}
            </Typography>
          </Box>

          <Button
            variant="contained"
            onClick={handleDelete}
            sx={{ mb: "1rem" }}
          >
            Delete
          </Button>
          <Button variant="contained" onClick={() => setOpenModal(false)}>
            Cancel
          </Button>
        </Box>
      </Modal>

      <div style={{ width: "100%" }}>
        <Grid container spacing={2}>
          <Grid item zeroMinWidth xs={12} sm={12} md={2}>
            <Box
              sx={{
                background: "linear-gradient(#eee, #fff)",
                height: "100%",
                minWidth: "175px",
              }}
            >
              <Navbar user={user} />
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={10}>
            <Box className="welcome-widget-container">
              <ParentWelcome user={user} />
              <WorkHoursStatusWidget />
            </Box>
            {openSpinner && (
              <Box
                sx={{ display: "flex", justifyContent: "center", mt: "2rem" }}
              >
                <CircularProgress color="primary" />
              </Box>
            )}
            <Box className="form-container" component="form" noValidate>
              <Box>
                {" "}
                <TextField
                  variant="outlined"
                  id="task"
                  label="Enter a task"
                  required
                  defaultValue={(tempEntry && tempEntry.description) || ""}
                  sx={{ width: "20rem" }}
                  onChange={(e) => setDescription(e.target.value)}
                  error={taskError}
                />
              </Box>

              <Box>
                {" "}
                <FormControl>
                  {menuOptions && (
                    <Select
                      id="workgroup"
                      variant="outlined"
                      required
                      defaultValue={
                        tempEntry && tempEntry.edit
                          ? tempEntry.workgroup_id
                          : "select"
                      }
                      sx={{ width: "20rem", marginTop: "1rem" }}
                      onChange={(e) => setWorkgroup_id(Number(e.target.value))}
                      error={workgroupError}
                    >
                      <MenuItem
                        value="select"
                        sx={{ color: "gray", fontStyle: "italic" }}
                      >
                        Select a workgroup *
                      </MenuItem>
                      {menuOptions &&
                        menuOptions.map((element, i) => {
                          return (
                            <MenuItem key={i} value={element.id}>
                              {element.name}
                            </MenuItem>
                          );
                        })}
                    </Select>
                  )}
                </FormControl>
              </Box>
              <Box>
                <TextField
                  id="date"
                  variant="outlined"
                  required
                  helperText="Date *"
                  pattern="\d{4}-\d{2}-\d{2}"
                  defaultValue={
                    tempEntry && tempEntry.edit ? tempEntry.date : () => date()
                  }
                  type="date"
                  sx={{
                    width: "20rem",
                    marginTop: "1rem",
                  }}
                  onChange={(e) => setDate(e.target.value)}
                  error={dateError}
                  inputProps={{
                    max: currentDate,
                    min: firstDay,
                  }}
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <TextField
                  id="hours"
                  variant="outlined"
                  endAdornment={
                    <InputAdornment position="end">Hours</InputAdornment>
                  }
                  required
                  type="text"
                  helperText="Hours *"
                  defaultValue={
                    tempEntry && tempEntry.edit
                      ? tempEntry.time_served.slice(
                          tempEntry.time_served.length - 5,
                          tempEntry.time_served.length - 3
                        )
                      : "00"
                  }
                  inputProps={{
                    min: "0",
                    max: "99",
                    maxLength: 2,
                  }}
                  onChange={(e) => setHours(e.target.value)}
                  error={hoursError}
                  sx={{
                    width: "9rem",
                    height: "3.5rem",
                    mt: "1rem",
                    mr: "1rem",
                  }}
                />
                <TextField
                  id="minutes"
                  variant="outlined"
                  type="text"
                  helperText="Minutes *"
                  required
                  defaultValue={
                    tempEntry && tempEntry.edit
                      ? tempEntry.time_served.slice(
                          tempEntry.time_served.length - 2,
                          tempEntry.time_served.length
                        )
                      : "00"
                  }
                  inputProps={{
                    min: "0",
                    max: "59",
                    maxLength: 2,
                  }}
                  onChange={(e) => {
                    if (e.target.value.length === 1) {
                      setMin(`0${e.target.value}`);
                    } else {
                      setMin(e.target.value);
                    }
                  }}
                  error={minError}
                  sx={{
                    width: "9rem",
                    height: "3.5rem",
                    mt: "1rem",
                    ml: "1rem",
                  }}
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                {tempEntry && tempEntry.edit ? (
                  <Button
                    variant="contained"
                    onClick={(e) => editWorkEntry(e)}
                    sx={{
                      backgroundColor: "#BF3228",
                      margin: "2.5rem 0.5rem",
                    }}
                  >
                    Update
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    onClick={() => createWorkEntry()}
                    sx={{
                      backgroundColor: "#BF3228",
                      margin: "2.5rem 0.5rem",
                    }}
                  >
                    Submit
                  </Button>
                )}
                {tempEntry && tempEntry.edit ? (
                  <Button
                    variant="contained"
                    onClick={() => setOpenModal(true)}
                    sx={{
                      backgroundColor: "#BF3228",
                      margin: "2.5rem 0.5rem",
                    }}
                  >
                    Delete
                  </Button>
                ) : (
                  <></>
                )}

                <Button
                  variant="contained"
                  onClick={() => navigate(-1)}
                  sx={{
                    backgroundColor: "#BF3228",
                    margin: "2.5rem 0.5rem",
                  }}
                >
                  Cancel
                </Button>
              </Box>
            </Box>

            <Box>
              <Divider />
              <Box sx={{ ml: "2rem", mr: "1rem", mb: "3rem" }}>
                <Typography variant="h6" color="text.secondary" mb={1} mt={2}>
                  Instructions
                </Typography>
                <Typography variant="body2" color="text.secondary" mb={1}>
                  Add a short description of the task you completed
                </Typography>
                <Typography variant="body2" color="text.secondary" mb={1}>
                  Select and assign to the relevant workgroup (or closest match)
                </Typography>
                <Typography variant="body2" color="text.secondary" mb={1}>
                  Add the date the activity took place (if the activity spans
                  multiple days or overnight, apply the date the activity was
                  completed)
                </Typography>
                <Typography variant="body2" color="text.secondary" mb={1}>
                  Add the number of hours and minutes to be recorded
                </Typography>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </div>
    </>
  );
}

export default LogHours;
