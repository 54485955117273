import AppLayout from '../components/AppLayout'
import {Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from '@mui/material'
import {useEffect, useState} from 'react'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import TableHead from '@mui/material/TableHead'
import TableContainer from '@mui/material/TableContainer'
import TableBody from '@mui/material/TableBody'
import Table from '@mui/material/Table'
import WorkHoursStatusWidget from '../components/WorkHoursStatusWidget'
import {HoursContextProvider} from '../contexts/HoursContext'
import LoggedHours from '../components/LoggedHours'
import {Link} from "react-router-dom";
import { useParams } from 'react-router';


function ViewHousehold(arg) {
    const { householdId } = useParams();
    const [household, setHousehold] = useState(null)
    const [workEntryToDelete, setWorkEntryToDelete] = useState(null)
    const [membershipPeriods, setMembershipPeriods] = useState([])
    const [settlements, setSettlements] = useState([])

    const loadHousehold = async () => {
        {
            const response = await fetch(`/api/v1/households/${householdId}`)
            const result = await response.json()
            setHousehold(result)
        }
        console.log("loadHouseholdPeriods for ", household)
        {
            const response = await fetch(`/api/v1/households/${householdId}/settlements`)
            const result = await response.json()
            setSettlements(result.settlements)
        }
        {
            const response = await fetch(`/api/v1/households/${householdId}/membership-periods`)
            const result = await response.json()
            setMembershipPeriods(result.membershipPeriods)
        }
    }

    useEffect(() => {
        loadHousehold()
        // eslint-disable-next-line
    }, [])

    const deleteWorkEntry = async (workEntry) => {
        console.log("delete work entry", workEntry)
        const response = await fetch(`/api/v1/work-entries/${workEntry.id}`, {
            method: "DELETE"
        })
        console.log('work entry deletion status ', response.status)
        setWorkEntryToDelete(null)
        // I could not quickly devise a better way to reload the work entry list, so
        const location = document.location
        document.location = location
    }

    const makeDeleteButton = (workEntry) =>
        <Button variant="standard"
                onClick={() => setWorkEntryToDelete(workEntry)}>
            Delete
        </Button>

    return (
        <AppLayout>
            <Dialog open={!!workEntryToDelete}
                    onClose={() => setWorkEntryToDelete(null)}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title">Delete work entry?</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Should the work entry "{workEntryToDelete?.description}" be deleted?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setWorkEntryToDelete(null)} autoFocus>Cancel</Button>
                    <Button onClick={() => deleteWorkEntry(workEntryToDelete)}>Delete</Button>
                </DialogActions>
            </Dialog>
            <Box sx={{display: "flex", justifyContent: "flex-begin"}}>
                <h2>Household { household?.members.map(({name}) => name).join(', ') }</h2>
            </Box>
            {
                membershipPeriods.length ?
                    <>
                        <h3>Membership Periods</h3>
                        <Box className="table-container-print">
                            <TableContainer>
                                <Table className="table-print" size="small">
                                    <TableHead sx={{ backgroundColor: "#eee" }}>
                                        <TableRow>
                                            <TableCell sx={{fontWeight: "bold"}}>Start</TableCell>
                                            <TableCell sx={{fontWeight: "bold"}}>End</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {
                                            membershipPeriods.map(({id, first_day, last_day}) =>
                                                <TableRow key={id}>
                                                    <TableCell sx={{fontWeight: "bold"}}>{ first_day }</TableCell>
                                                    <TableCell sx={{fontWeight: "bold"}}>{ last_day }</TableCell>
                                                </TableRow>)
                                        }
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Box>
                    </>
                    : <></>
            }
            {
                settlements.length ?
                    <>
                        <h3>Settlements</h3>
                        <Box className="table-container-print">
                            <TableContainer>
                                <Table className="table-print" size="small">
                                    <TableHead sx={{ backgroundColor: "#eee" }}>
                                        <TableRow>
                                            <TableCell sx={{fontWeight: "bold"}}>Start</TableCell>
                                            <TableCell sx={{fontWeight: "bold"}}>End</TableCell>
                                            <TableCell sx={{fontWeight: "bold"}}>Settled</TableCell>
                                            <TableCell sx={{fontWeight: "bold"}}>Served</TableCell>
                                            <TableCell sx={{fontWeight: "bold"}}>Required</TableCell>
                                            <TableCell sx={{fontWeight: "bold"}}>Carry Over</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {
                                            settlements.map(({id, first_day, last_day, settled_at, time_required, time_served, carry_over}) =>
                                                <TableRow key={id}>
                                                    <TableCell sx={{fontWeight: "bold"}}>{ first_day }</TableCell>
                                                    <TableCell sx={{fontWeight: "bold"}}>{ last_day }</TableCell>
                                                    <TableCell sx={{fontWeight: "bold"}}>{ settled_at }</TableCell>
                                                    <TableCell sx={{fontWeight: "bold"}}>{ time_served }</TableCell>
                                                    <TableCell sx={{fontWeight: "bold"}}>{ time_required }</TableCell>
                                                    <TableCell sx={{fontWeight: "bold"}}>{ carry_over }</TableCell>
                                                </TableRow>)
                                        }
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Box>
                    </>
                    : <></>
            }
            {
                household &&
                <>
                    <h3>Logged hours</h3>
                    <LoggedHours household={household} makeLogEntryActions={makeDeleteButton}/>
                    <Link to={"/loghours?householdId=" + household.id + "&householdName=" + household.label}>
                        <Button variant="contained"
                                sx={{
                                    backgroundColor: "#BF3228",
                                    mt: "2rem",
                                    mb: "2rem",
                                    mr: "0.5rem"
                                }}>
                            + Add new task
                        </Button>
                    </Link>
                </>
            }
            {
                household &&
                <HoursContextProvider household={household}>
                    <h3>Balance</h3>
                    <WorkHoursStatusWidget/>
                </HoursContextProvider>
            }
        </AppLayout>
    );
}

export default ViewHousehold;
