import { createContext, useState } from "react";

export const WorkEntriesContext = createContext();

export const WorkEntriesContextProvider = (props) => {
  // * Set temporary work hour entry
  const [tempEntry, setTempEntry] = useState({ edit: false });

  return (
    <>
      <WorkEntriesContext.Provider value={{ tempEntry, setTempEntry }}>
        {props.children}
      </WorkEntriesContext.Provider>
    </>
  );
};
