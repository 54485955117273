import React from "react";
import {
  Box,
  Typography,
  Avatar,
  MenuItem,
  Divider,
  Collapse,
  Switch,
  FormGroup,
  FormControlLabel,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useState } from "react";

function Navbar(props) {
  const user = props.user;
  const navigate = useNavigate();
  const [checked, setChecked] = useState(true);
  const [open, setOpen] = useState(true);

  const logout = () => {
    document.location.href = "/logout";
  };

  return (
    <div>
      <Box className="navbar-container">
        <Box className="user-info">
          <Avatar src="" sx={{ mb: "1rem" }} />

          <Typography variant="h6">{user?.name}</Typography>
          <Typography variant="subtitle1" color="text.secondary">
            {user?.is_admin ? "Admin" : "Parent"}
          </Typography>
        </Box>
        <Box>
          {" "}
          <Box className="switch-button">
            <FormGroup>
              <FormControlLabel
                label="Show menu"
                labelPlacement="start"
                control={
                  <Switch
                    sx={{ margin: "0rem" }}
                    checked={checked}
                    onChange={() => {
                      if (open) {
                        setOpen(false);
                      } else {
                        setOpen(true);
                      }
                      if (checked) {
                        setChecked(false);
                      } else {
                        setChecked(true);
                      }
                    }}
                  />
                }
              />
            </FormGroup>
          </Box>
        </Box>
      </Box>

      <Collapse in={open} onClose={() => setOpen(false)}>
        <Divider sx={{ mb: "1rem" }} />
        <Box>
          <MenuItem onClick={() => navigate("/")}>
            Work Hours
          </MenuItem>
        </Box>

        {user?.is_admin ? (
          <>
            <Divider sx={{ mt: "1rem", mb: "1rem" }} />
            <Box>
              <MenuItem onClick={() => navigate("/households")}>Households</MenuItem>
              <MenuItem onClick={() => navigate("/billing-periods")}>Billing Periods</MenuItem>
            </Box>
          </>
        ) : (
          <></>
        )}

        <Divider sx={{ mt: "1rem", mb: "1rem" }} />

        <Box>
          <MenuItem onClick={logout}>Logout</MenuItem>
        </Box>
      </Collapse>
    </div>
  );
}

export default Navbar;
