import { useNavigate } from "react-router-dom";
import {useEffect, useState} from 'react'
import AppLayout from '../components/AppLayout'
import {Autocomplete, Box, TextField} from '@mui/material'
import {useSearchParams} from "react-router-dom";

function ViewHouseholds() {
  const [names, setNames] = useState([])
  const [selectedHousehold, setSelectedHousehold] = useState(null)
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  console.log('selectedHousehold', selectedHousehold, ' household name ', searchParams.get('householdName'))

  const getHouseholds = async () => {
    try {
      const response = await fetch("/api/v1/households");
      const result = await response.json();
      const households = result.households;
      const names = households?.map(({household_id, members}) => {
        return {
          label: members.join(', '),
          id: household_id,
        }
      })
      names.sort((a, b) => a.label.localeCompare(b.label));
      setNames(names);
      console.log("Names: ", names);
    } catch (error) {
      console.log("Unable to retrieve households.", error);
    }
  }

  useEffect(() => {
    getHouseholds();
  }, []);

  const householdChanged = (event, household) => {
    setSelectedHousehold(household);
    setSearchParams({ householdName: household.label });
    navigate("/household/" + household.id)
  }

  return (
    <AppLayout>
      <Box sx={{display: "flex", justifyContent: "flex-begin"}}>
        <h2>Households</h2>
      </Box>
      <Box>
        <Autocomplete disablePortal
                      id="combo-box-demo"
                      options={names}
                      sx={{ width: 500 }}
                      onChange={householdChanged}
                      isOptionEqualToValue={(option, value) => option.id === value.id}
                      renderInput={(params) => <TextField {...params} label="Name" />}/>
      </Box>
    </AppLayout>
  );
}

export default ViewHouseholds;
