import React from "react";
import LogHours from "../components/LogHours";

function ViewLogHours() {
  return (
    <div>
      <LogHours />
    </div>
  );
}

export default ViewLogHours;
