import React from "react";
import { useSearchParams } from 'react-router-dom'
import { Box, Typography } from "@mui/material";

function ParentWelcome(props) {
  const user = props.user;
  const [searchParams] = useSearchParams();
  const logForHouseholdName = searchParams.get("householdName");
  const household_name = logForHouseholdName || user?.household_name;
  return (
    <div>
      <Box sx={{ display: "flex", flexDirection: "row" }}>
        <Box className="user-welcome-box">
          <Typography variant="h6">Welcome, {user && user.name}!</Typography>
          {household_name ? (
            <Typography variant="h6" color="text.secondary">
              Family: {household_name}
            </Typography>
          ) : (
            <Typography variant="h6" color="text.secondary">
              Family: No household assigned
            </Typography>
          )}
        </Box>
      </Box>
    </div>
  );
}

export default ParentWelcome;
